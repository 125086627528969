export const globalFunc = {
  slugify: (text = "", ampersand = "and") => {
    const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧğşı";
    const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzhgsi";
    const p = new RegExp(a.split("").join("|"), "g");

    return text
      .toString()
      .toLowerCase()
      .replace(/[\s_]+/g, "-") // Replace whitespace and underscore with single hyphen
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special chars
      .replace(/&/g, `-${ampersand}-`) // Replace ampersand with custom word
      .replace(/[^\w-]+/g, "") // Remove all non-word chars
      .replace(/--+/g, "-") // Replace multiple hyphens with single
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  },

  isURL: (str) => {
    return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str);
  },

  copyTextToClipboard: (text) => {
    navigator.clipboard.writeText(text);
  },

  haversineDistance(loc1, loc2, unit = "km") {
    const isLoc1Null = Object.values(loc1).some((value) => value === null);
    const isLoc2Null = Object.values(loc2).some((value) => value === null);
    if (isLoc1Null || isLoc2Null) {
      return null;
    }

    // var R = 3958.8;  // Radius of the Earth in miles
    // var R = 6371;    // Radius of the Earth in KM

    var R = unit == "mi" ? 3958.8 : 6371;
    var rlat1 = loc1.lat * (Math.PI / 180); // Convert degrees to radians
    var rlat2 = loc2.lat * (Math.PI / 180); // Convert degrees to radians
    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (loc2.lng - loc1.lng) * (Math.PI / 180); // Radian difference (longitudes)

    var d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2)
        )
      );
    return d;
  },

  fixVAT(vatNumber) {
    if (typeof vatNumber === "undefined" || vatNumber === null) {
      return null;
    }

    // Remove the country ISO code
    if (/^[A-Z]{2}/.test(vatNumber)) {
      vatNumber = vatNumber.substring(2);
    }

    // Remove the dots
    vatNumber = vatNumber.replace(/\./g, "");

    // Remove non-digit characters
    // vatNumber = vatNumber.replace(/\D/g, '');

    // If vatNumber is 9 characters long, add '0' at the beginning if it's not '0'.
    if (vatNumber.length === 9) {
      vatNumber = vatNumber.replace(/^(?!0)/, "0");
    }

    // If vatNumber has a length of 11 characters, remove leading zeros.
    if (vatNumber.length === 11) {
      vatNumber = vatNumber.replace(/^0+/, "");
    }

    return vatNumber;
  },
  checkVAT(vatNumber, countryId) {
    vatNumber = this.fixVAT(vatNumber);

    if (vatNumber === null) {
      return true;
    }

    const numLength = vatNumber.length;

    if (countryId === 1) {
      // Belgium
      let regex = /^0[0-9]{9}$|^[0-9]{10}$/;
      if (regex.test(vatNumber)) {
        // Kontrol basamaklarını hesapla
        var check = 97 - (vatNumber.substring(0, numLength - 2) % 97);
        return check === Number(vatNumber.slice(-2));
      }
    } else if (countryId === 2) {
      // Netherlands
      // let regex = /^[0-9]{9}B[0-9]{2}$/;
      let regex = /^[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}$/;
      if (regex.test("NL" + vatNumber)) {
        // Hollanda'nın doğrulama algoritması şu anda kullanılmıyor,
        // Ancak özel bir algoritma gerekirse, burada uygulanmalıdır.
        return true;
      }
    }
  },

  replaceEmptyStringsWithNull(obj) {
    return Object.fromEntries(
      Object.entries(obj).map(([k, v]) => [
        k,
        v === ""
          ? null
          : v && typeof v === "object"
          ? replaceEmptyStringsWithNull(v)
          : v,
      ])
    );
  },

  // The extractKeys returns the new object with the specified keys
  extractKeys(obj, keys) {
    return keys.reduce((newObj, key) => {
      newObj[key] = obj && obj.hasOwnProperty(key) ? obj[key] : null;
      return newObj;
    }, {});
  },

  formatDateTime(date) {
    return new Date(date).toLocaleString();
  },

  formatTime(time) {
    return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }
};

export const globalVar = {
  emailLabels: [
    // { label: "Urgent", color: "warning", value: "urgent" },
    // { label: "Important", color: "danger", value: "important" },
    { label: "Waiting", color: "warning", value: "waiting", progress: 10 },
    { label: "In-Progress", color: "info", value: "in-progress", progress: 50 },
    { label: "Done", color: "success", value: "done", progress: 100 },
  ],

  countries: [
    { value: 1, label: "Belgium", code: "BEL", iso2: "be", flag: "cifBe" },
    { value: 2, label: "Netherlands", code: "NLD", iso2: "nl", flag: "cifNl" },
  ],

  originators: [
    { value: null, label: "Not specified" },
    { value: 0, label: "Menute" },
    { value: 1, label: "Representative" },
  ],

  messageChannels: [
    { value: "email", label: "Email" },
    { value: "push", label: "Push Notification" },
    { value: "sms", label: "SMS" },
    // { value: "whatsapp", label: "WhatsApp" },
  ],
  messageSegments: [
    { value: "User", label: "All Users" },
    { value: "Leads", label: "Leads" },
    { value: "UsersWithOrders", label: "Users with Location", description: "Users who have placed a real order and subscribed through the app." },
    { value: "NoRecentOrdersUsers", label: "Users with No Recent Orders", description: "Users without orders from mainsite or app in the last 3 months." },
    { value: "HighPointUsers", label: "High Point Users", description: "Users with more than 200 points." },

    { value: "Guest", label: "Guest" },

    { value: "Admin", label: "Admins" },
    { value: "Courier", label: "Couriers" },
    { value: "Restaurant", label: "Restaurants" },
  ],

  // The following permissions are used for the courier role.
  courierPermissions: [
    {
      id: 1,
      name: "can_take_breaks",
      description:
        'The "can_take_breaks" permission allows the courier to take scheduled breaks during their shift.',
      default: true,
    },
    {
      id: 2,
      name: "can_reject_orders",
      description:
        'The "can_reject_orders" permission allows the courier to reject assigned orders if necessary.',
      default: false,
    },
    {
      id: 3,
      name: "can_have_multiple_orders",
      description:
        'The "can_be_assigned_multiple_orders" permission allows the courier to be assigned multiple orders at the same time.',
      default: true,
    },
    {
      id: 4,
      name: "requires_pickup_code",
      description:
        'The "requires_pickup_code" permission mandates that the courier must use a specific code to pick up orders, ensuring secure and authorized pickups.',
      default: true,
    },
    {
      id: 5,
      name: "requires_check_max_pickup_distance",
      description:
        'The "requires_check_max_pickup_distance" permission prevents the courier from picking up an order if they are beyond a specified distance from the restaurant.',
      default: true,
    },
  ],
};
