import axios from "axios";
import store from "@/store";
import router from '@/router'

export const axiosInstance = axios.create({
  baseURL: router.baseURL
});

// axiosInstance.interceptors.request.use(config => {
//   if (localStorage.getItem("authToken")) {
//     config.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
//   }
//   return config;
// });

axiosInstance.interceptors.request.use(function (config) {
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
    Accept: "application/json",
    'country': store.state.countryId,
    'ngrok-skip-browser-warning': true
  };

  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    switch (error.response.status) {
      case 422:
        store.commit("setErrors", error.response.data.message);
        return Promise.reject(error);

      case 401: // Unauthorized access!
        store.commit("auth/loggedOut");

        if (router.history.current.name !== 'Login')
          router.push({ name: "Login" });
        else
          return Promise.reject(error);
        break;

      case 403: // Forbidden: You don't have permission!
        store.commit("setErrors", error.response.data.message);
        router.push({ name: "Page403" });
        return Promise.reject(error);

      default:
        let message = error.response.data;
        delete message.trace;
        store.commit("setErrors", message);
        return Promise.reject(error);
    }
  });
